import { nanoid } from "@reduxjs/toolkit";

import SodService from "./sod-service";


class LockService extends SodService {
    async getLocks(params) {
        const config = this.generateConfigForGetEntities(params);

        const data = await this.get("/core/locks", config);

        const locks = data?.rows.map((lock) => this.parseLock(lock));
        const total = data?.total;

        return { locks, total };
    }

    async getLocksColumns() {
        return this.getColumns("/core/locks/columns");
    }

    async lockObject(endpoint, action, id, sessionId=null) {
        await this.post(endpoint, {action: action, object_id: id, session_id: sessionId});
    }

    async lockSystem(action, id, sessionId) {
        await this.lockObject("/core/systems/locks", action, id, sessionId);
    }

    async lockCriticalObject(action, type, { name, system }, sessionId){
        const body = {
            action,
            crit_object_name: name,
            system_id: system,
            object_type: type,
            session_id: sessionId
        };

        await this.post("/sod/critical-objects/locks", body);
    }

    async lockCriticalRole(action, keys, sessionId=null) {
        await this.lockCriticalObject(action, "critical_role", keys, sessionId);
    }

    async lockCriticalProfile(action, keys, sessionId=null) {
        await this.lockCriticalObject(action, "critical_profile", keys, sessionId);
    }

    async lockSystemGroup(action, id, sessionId=null) {
        await this.lockObject("/core/systems/system-groups/locks", action, id, sessionId);
    }

    async lockFunction(action, id, sessionId=null) {
        await this.lockObject("/sod/functions/locks", action, id, sessionId);
    }

    async lockMatrixHeader(action, id, sessionId=null) {
        await this.lockObject("/sod/matrix-headers/locks", action, id, sessionId);
    }

    async lockRisk(action, id, sessionId=null) {
        await this.lockObject("/sod/risks/locks", action, id, sessionId);
    }

    async lockRiskLevels(action, key, sessionId=null) {
        await this.lockObject("/sod/risks/risk-levels/locks", action, null, sessionId);
    }

    async lockUser(action, username, sessionId=null) {
        await this.lockObject("/core/auth/users/locks", action, username, sessionId);
    }

    async lockCustomRole(action, {role, systemId}, sessionId){
        const body = {
            action,
            role: role,
            system_id: systemId,
            session_id: sessionId
        };

        await this.post("/core/custom-roles/locks", body);
    }
    
    async lockBusynessProcess(action, busynessProcessId, sessionId=null) {
        await this.lockObject("/sod/business-processes/locks", action, busynessProcessId, sessionId);
    }

    async getLocksFilters(params) {
        return this.getFilters("/core/locks/filters", params);
    }

    async deleteLock(entityId, key) {
        return this.delete(`/core/locks?entity_id=${entityId}&key=${key}`)
    }

    async lockSummaryReportSchedule(action, key, sessionId=null) {
        await this.lockObject("/sod/reports/user-busyness-level/locks", action, null, sessionId);
    }

    parseLock(lock) {
        return {
            id: nanoid(),
            entityId: lock.entity_id,
            entityText: lock.object_type,
            key: lock.object_id,
            username: lock.locked_by
        };
    }
}

const lockService = new LockService();

export { lockService };
export default LockService;